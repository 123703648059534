<template>
  <div class="home">
    <div class="container">
      <header class="header">
        <div class="header-container">
          <img
            src="@/assets/img/LOGO_PMVV_2021.png"
            alt="Logo PMVV"
            class="header-container__img"
          >
          <span class="header-container__text">
            Aqui você tem acesso aos sistemas do UpCities da prefeitura de Vila
            Velha
          </span>
        </div>
      </header>
      <div class="body">
        <a
          href="http://admin-pmvv.upcities.app"
          class="btn btn-primary"
        >
          Aplicativo - Painel Administrativo
        </a>
        <a
          href="http://agendamento-pmvv.upcities.app"
          class="btn btn-primary"
        >
          Agendamento - Painel Administrativo
        </a>
        <a
          href="http://ouvidoria-pmvv.upcities.app"
          class="btn btn-primary"
        >
          Ouvidoria - Painel Administrativo
        </a>
      </div>

      <footer class="footer">
        <div class="footer-container">
          <small class="footer-container__text">
            Um produto
          </small>
          <img
            src="@/assets/img/logo-upcities.svg"
            alt="Logo UpCities"
            class="footer-container__img"
          >
        </div>
        <div class="footer-container__social">
          <a
            href="https://www.facebook.com/uppersoft.tecnologia"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src="@/assets/img/gerenciador-contas-facebook-icon.svg"
              alt="Logo Facebook"
              class="footer-container__social-img"
            >
          </a>
          <a
            href="https://br.linkedin.com/company/uppersoft-tecnologia"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src="@/assets/img/gerenciador-contas-linkedin-icon.svg"
              alt="Logo LinkedIn"
              class="footer-container__social-img"
            >
          </a>
          <a
            href="https://www.instagram.com/uppersoft_/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src="@/assets/img/gerenciador-contas-instagram-icon.svg"
              alt="Logo Instagram"
              class="footer-container__social-img"
            >
          </a>
          <a
            href="https://www.youtube.com/channel/UCkxVLxUbyo3G2My5TmeN4xw"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src="@/assets/img/gerenciador-contas-youtube-icon.svg"
              alt="Logo YouTube"
              class="footer-container__social-img"
            >
          </a>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
};
</script>

<style lang="scss" scoped>
.home {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    place-content: space-around;
  }
}

.header {
  padding: 0 1rem;

  &-container {
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    align-items: center;

    &__text {
      color: $white;
      font-size: $text-lg;
    }

    &__img {
      width: 60%;
    }
  }
}

.body {
  width: 100%;
  padding: 0 1rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.footer {
  width: 100%;
  padding: 0 5rem;

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__text {
      color: $gray-100;
      font-size: $text-xs;
    }

    &__img {
      width: 100%;
      height: 30px;
      margin-bottom: 1rem;
    }

    &__social {
      display: flex;
      align-items: center;
      flex-direction: row;
      place-content: space-around;
    }
  }
}

@include screen(phone-big-up) {
  .header {
    margin: 2rem 6rem;
  }

  .body {
    padding: 0 6rem;
  }

  .footer {
    padding: 0 12rem;

    &-container {
      &__img {
        height: 30px;
      }
    }
  }
}

@include screen(tablet-up) {
  .header {
    margin: 2rem 9rem;
  }

  .body {
    padding: 0 9rem;
  }

  .footer {
    padding: 0 17rem;

    &-container {
      &__img {
        height: 30px;
      }
    }
  }
}

@include screen(tablet-big-up) {
  .header {
    margin: 2rem 17rem;
  }

  .body {
    padding: 0 17rem;
  }

  .footer {
    padding: 0 25rem;

    &-container {
      &__img {
        height: 30px;
      }
    }
  }
}

@include screen(desktop-up) {
  .header {
    margin: 2rem 25rem;
  }

  .body {
    padding: 0 25rem;
  }

  .footer {
    padding: 0 33rem;

    &-container {
      &__img {
        height: 30px;
      }
    }
  }
}
</style>
